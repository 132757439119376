import analytics from 'react-segment';

/*
Usage:
initialize: Analytics.init('e8jHwSMD5aKOa2c7x1LPTxGBFkuJqu4Y');
log/track event: Analytics.track().page('Homepage from app track 007');
https://github.com/segmentio/analytics-react
https://github.com/anishmprasad/react-segment
*/
const Analytics = {
  init: (secretKey) => {
    if (!secretKey) {
      console.error('Invalid Key');
      return;
    }
    analytics.default.load(secretKey);
  },
  record: () => {
    //Temporary Fix for disabling for Non- Prod
    if (process.env.REACT_APP_ENV != 'prod') {
      console.log('Block sending event to Sentry for Non Prod');
      return {
        track: () => console.log(),
        page: () => console.log(),
      };
    }

    return window.analytics;
  },
};

export { Analytics };
