/**
 * To set the key-value pair to Session storage
 * @param {string} key
 * @param {any} value
 * @returns N/A
 */
const setToSessionStorage = (key: string, value: any) => {
  try {
    if (!key || typeof key !== 'string') {
      throw new Error('Invalid key');
    }
    const serializedData = JSON.stringify(value);
    sessionStorage.setItem(key, serializedData);
  } catch (error) {
    throw new Error(error);
  }
};

/*
 * To get the value from session storage that matches the given key
 * @param {string} key
 * @returns The value of the key argument
 */
const getFromSessionStorage = (key: string) => {
  try {
    if (!key || typeof key !== 'string') {
      throw new Error('Invalid key');
    }

    const serializedData = sessionStorage.getItem(key)!;
    const dataArray = JSON.parse(serializedData);
    return dataArray;
  } catch (error) {
    throw new Error(error);
  }
};

/* To remove the key-value pair from Session storage
 * @param {string} key
 * @returns N/A
 */
const removeFromSessionStorage = (key: string) => {
  try {
    if (!key || typeof key !== 'string') {
      throw new Error('Invalid key');
    }

    sessionStorage.removeItem(key);
  } catch (error) {
    throw new Error(error);
  }
};

export { getFromSessionStorage, removeFromSessionStorage, setToSessionStorage };
