import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Analytics } from 'analytics/segments';
import Amplify from 'aws-amplify';
import { ErrorBoundary } from 'components/error-boundary';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import AppProviders from './context';

if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
  Analytics.init(process.env.REACT_APP_SEGMENT_WRITE_KEY);
}

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
});

const sentryEnvs = ['uat', 'prod'];
if (sentryEnvs.includes(process.env.REACT_APP_ENV as string)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    autoSessionTracking: true,
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_ENV === 'prod' ? 0.5 : 0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

ReactDOM.render(
  <AppProviders>
    <Sentry.ErrorBoundary>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
    {process.env.REACT_APP_ENV === 'local' && (
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    )}
  </AppProviders>,
  document.getElementById('root')
);
