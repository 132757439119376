import 'antd/dist/antd.css';
import { useCountrySetting } from 'core/services/allCountryDetailsService';
import React, { lazy, Suspense } from 'react';
import 'syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less';
import './api/api';
import { Loader } from './components/loader';
import './main.less';

export const App = () => {
  const { modalDetails, modalDetailsLoader } = useCountrySetting();
  const RouterComponent = lazy(() => import('./AppRouter'));
  return modalDetails?.data.length > 0 && !modalDetailsLoader ? (
    <Suspense fallback={<Loader tip={``} />}>
      <RouterComponent />
    </Suspense>
  ) : (
    <Loader tip={''} />
  );
};

export default App;
