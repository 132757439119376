import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '../core/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
    mutations: {
      // mutation options
    },
  },
});

function AppProviders(props: any) {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>{props.children}</ThemeProvider>
    </QueryClientProvider>
  );
}

export default AppProviders;
