/*
 * To get the value from local storage that matches the given key
 * @param {string} key
 * @returns The value of the key argument
 */
const getFromLocalStorage = (key: string) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid key');
  }

  /**
   * Handle non-string value with JSON.parse.
   * Catch string value and return it
   */
  try {
    return JSON.parse(localStorage.getItem(key)!);
  } catch (e) {
    return localStorage.getItem(key);
  }
};

/**
 * To set the key-value pair to local storage
 * @param {string} key
 * @param {any} value
 * @returns N/A
 */
const setToLocalStorage = (key: string, value: any) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid key');
  }

  if (typeof value === 'object') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

/**
 * To remove the key-value pair from local storage
 * @param {string} key
 * @returns N/A
 */
const removeFromLocalStorage = (key: string) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid key');
  }

  localStorage.removeItem(key);
};

export { getFromLocalStorage, setToLocalStorage, removeFromLocalStorage };
