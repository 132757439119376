import { Button } from 'antd';
import { PageLayout } from 'components/page-layout';
import get from 'lodash/get';
import React, { ErrorInfo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;
const Label = styled.h1`
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 46px;
  color: rgb(46, 51, 125);
`;
export const StyledButton = styled(Button)<{ type: any }>`
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => get(props, 'theme.fontSizes.medium')};
  margin: 0 0 0 16px;
  line-height: 20px;
  @media (min-width: 1024px) and (max-width: 1365px) {
    width: 100%;
  }
`;

class ErrorBoundary extends React.Component<{}, { hasError: boolean; error: any; info: any }> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }
  public componentDidCatch(error: Error, info: ErrorInfo) {
    // tslint:disable-next-line:no-console
    console.error(error, info);
    this.setState({ hasError: true, error, info });
  }
  public render() {
    if (this.state.hasError) {
      return PageLayout({
        header: null,
        content: (
          <Container>
            <Label>Something went wrong</Label>
            <p>{'Please try after some time'}</p>{' '}
            <StyledButton type="primary" htmlType="submit" onClick={() => window.location.reload()}>
              Try Again
            </StyledButton>
          </Container>
        ),
      });
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
