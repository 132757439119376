import currency from 'currency.js';

export const convertAmount = (amount: number, exchangeRate: number): number | undefined => {
  if (
    typeof amount !== 'number' ||
    typeof exchangeRate !== 'number' ||
    isNaN(amount) ||
    isNaN(exchangeRate)
  ) {
    return 0;
  } else if (exchangeRate === 0) {
    return amount;
  }
  return currency(amount, { precision: 2 }).divide(exchangeRate).value;
};

export const convertNumberToMillions = (labelValue) =>
  // Six Zeroes for Millions
  Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
    : // less than Three Zeroes for Thousands then show direct number
    Math.abs(Number(labelValue)) < 1.0e3
    ? Math.abs(Number(labelValue))
    : 0;
