import { ErrorBoundary } from 'components/error-boundary';
import React from 'react';
import styled from 'styled-components';
import { Spin } from 'syngenta-digital-cropwise-react-ui-kit';

const StyledLoader = styled.div`
  position: fixed;
  z-index: 10;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
`;
const StyledSpinContainer = styled.div`
  position: absolute;
  left: ${(props) => (window.location.pathname === '/signup' ? '20%' : '50%')};
  top: 50%;
  @media screen and (max-width: 768px) {
    transform: ${() => (window.location.pathname === '/signup' ? 'none' : 'translate(-50%, -50%)')};
  }
`;
interface ILoaderProps {
  tip: string;
}

export const Loader: React.FC<ILoaderProps> = (props) => (
  <ErrorBoundary>
    <StyledLoader>
      <StyledSpinContainer>
        <Spin spinning={true} delay={500} tip={props.tip} />
      </StyledSpinContainer>
    </StyledLoader>
  </ErrorBoundary>
);
