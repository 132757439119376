import { message } from 'antd';
import { API } from 'config/api/apiUrls';
import { ALL } from 'core/constants';
import { CountryCodes, CountryCodeUrls } from 'core/utils/countryUtils';
import { setToLocalStorage } from 'core/utils/localStorage';
import { useQuery } from 'react-query';

export const getAllCountryDetails = async (country, type?) => {
  const res: Response = await fetch(
    type ? `${API.modal_detail}/${country}?type=${type}` : API.modal_detail + `${country}`
  );

  const data = await res.json();
  return { data: data.data, message: data.message, resultCode: data.resultCode };
};

export const useCountrySetting = () => {
  const { data: modalDetails, isLoading: modalDetailsLoader } = useQuery(
    'fetchAllCountryDetails',
    () => getAllCountryDetails(CountryCodes.Russia, ALL),
    {
      refetchOnWindowFocus: false,
      enabled: true,
      cacheTime: 0,

      onSuccess: (response) => {
        if (response?.resultCode == 'S00' && response?.data?.length > 0) {
          const country = response?.data.find((res) => {
            if (
              window.location.pathname.indexOf(res.country_url) === 0 ||
              window.location.pathname === res.country_url.slice(0, -1)
            ) {
              return res;
            }
          });

          const countryDetails = response.data.find((c) => {
            if (c.country_url.includes(country?.country_url)) {
              return c;
            }
          });

          // If Country code not presend in URL then set default one as Russia
          if (!countryDetails && !window.location.pathname.includes('superadmin')) {
            window.location.href = window.location.origin + CountryCodeUrls[CountryCodes.Russia];
          }

          const defaultCountryDetails = response.data.find((c) =>
            c.country_url.includes(CountryCodeUrls[CountryCodes.US])
          );
          const onboardedCountryCodes = {};
          response.data.forEach((c) => (onboardedCountryCodes[c.name] = c.country_code));

          setToLocalStorage('onboarded_country_codes', onboardedCountryCodes);

          const onboardedCountryCodeUrls = {};
          response.data.forEach((c) => (onboardedCountryCodeUrls[c.country_code] = c.country_url));

          setToLocalStorage('onboarded_country_code_urls', onboardedCountryCodeUrls);

          setToLocalStorage(
            'country_details',
            countryDetails ? countryDetails : defaultCountryDetails
          );

          const onboardedCountryCurrency = {};
          response.data.forEach((c) => (onboardedCountryCurrency[c.currency] = c.currency));

          setToLocalStorage('onboarded_country_currency', onboardedCountryCurrency);
        }
      },
      onError: (err: any) => {
        message.error(err);
      },
    }
  );

  return { modalDetails, modalDetailsLoader };
};
