import { Auth } from 'aws-amplify';
import axios from 'axios';
import { history, RoutePaths } from 'core/history';
import { getCountryCode } from 'core/utils/countryUtils';

const headers = {
  'Content-Type': 'application/json',
};

const baseURL = process.env.REACT_APP_API_URL;

export const request = axios.create({
  baseURL,
  headers,
});

request.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    config.headers.Authorization = `${idToken}`;
    config.headers['country-code'] = getCountryCode();
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(new Error(error));
  }
);

// update the response if require and manage the log
request.interceptors.response.use(
  (res) => {
    console.log(`this is response iterceptor ${res}`);
    return res;
  },
  async (error) => {
    if (error.response?.status === 401) {
      try {
        await Auth.signOut({ global: true });
      } catch (e) {
        console.log('Error', e);
      }
      history.replace(RoutePaths.HOME());
    }
    return Promise.resolve(error.response);
  }
);
