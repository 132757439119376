import { getFromLocalStorage } from './localStorage';

export const countryDetailsFromStorage = () => getFromLocalStorage('country_details') ?? {};

export const countryCodesFunc = () => {
  const countryCodes = getFromLocalStorage('onboarded_country_codes')
    ? getFromLocalStorage('onboarded_country_codes') ?? {}
    : {
        Russia: 'RU',
      };

  return countryCodes;
};

export const CountryCodes = countryCodesFunc();

export const countryCodeUrlsFunc = () => {
  const countryCodeUrls = getFromLocalStorage('onboarded_country_code_urls')
    ? getFromLocalStorage('onboarded_country_code_urls') ?? {}
    : {
        RU: '/ru/',
      };

  return countryCodeUrls;
};

export const CountryCodeUrls = countryCodeUrlsFunc();

export const getDefaultCountryCode = () =>
  getFromLocalStorage('login_country_url') || CountryCodeUrls[CountryCodes.Russia];

export const getExactCountryCodeFromRoute = () => {
  const country = countryDetailsFromStorage();
  return country.country_url;
};

export const isUrlHavingCountryCode = () =>
  window.location.pathname.includes('superadmin') || getExactCountryCodeFromRoute();

export const getCountryCodeFromRoute = () => {
  const country = countryDetailsFromStorage();
  return country.country_url;
};

export const getCountryCode = () => {
  const country = countryDetailsFromStorage();
  return country.country_code ?? CountryCodes.Russia;
};

export const getCountryName = () => {
  const country = countryDetailsFromStorage();
  return country.name;
};

export const getCountryModal = () => {
  const country = countryDetailsFromStorage();
  return country.modal_type;
};
