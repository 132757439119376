export enum RequestStatus {
  Registration_Draft = 'Registration_Draft',
  Registration_Submitted = 'Registration_Submitted',
  Registration_Rejected = 'Registration_Rejected',
  Registration_Approved = 'Registration_Approved',
  Agrobonus_Draft = 'Agrobonus_Draft',
  Agrobonus_Contract_Admin_Submitted = 'Agrobonus_Contract_Admin_Submitted',
  Agrobonus_Contract_Admin_Approved = 'Agrobonus_Contract_Admin_Approved',
  Agrobonus_Contract_Admin_Rejected = 'Agrobonus_Contract_Admin_Rejected',
  Growth_Draft = 'Growth_Draft',
  Growth_GRN_Draft = 'Growth_GRN_Draft',
  Growth_GRN_Admin_Submitted = 'Growth_GRN_Admin_Submitted',
  Growth_GRN_Admin_Approved = 'Growth_GRN_Admin_Approved',
  Growth_Bonus_Agreement_Draft = 'Growth_Bonus_Agreement_Draft',
  Growth_Bonus_Agreement_Submitted = 'Growth_Bonus_Agreement_Submitted',
  Growth_Bonus_Agreement_Approved = 'Growth_Bonus_Agreement_Approved',
  Growth_GRN_Admin_Rejected = 'Growth_GRN_Admin_Rejected',
  Growth_Bonus_Agreement_Reject = 'Growth_Bonus_Agreement_Reject',
  PRICE_Fixation_Draft = 'PRICE_Fixation_Draft',
  Price_Fixation_Admin_Submitted = 'Price_Fixation_Admin_Submitted',
  Price_Fixation_Admin_Addendum_Draft = 'Price_Fixation_Admin_Addendum_Draft',
  Price_Fixation_Admin_System_Processed = 'Price_Fixation_Admin_System_Processed',
  Price_Fixation_Grower_Addendum_Submitted = 'Price_Fixation_Grower_Addendum_Submitted',
  Price_Fixation_Grower_Addendum_Approved = 'Price_Fixation_Grower_Addendum_Approved',
  Price_Fixation_Grower_Addendum_Rejected = 'Price_Fixation_Grower_Addendum_Rejected',
  Agrobonus_Bonus_Agreement_Downloaded = 'Agrobonus_Bonus_Agreement_Downloaded',
  Addendum1_Downloaded = 'Addendum1_Downloaded',
  Growth_Bonus_Agreement_Downloaded = 'Growth_Bonus_Agreement_Downloaded',
  Price_Fixation_Admin_Inprogress = 'Price_Fixation_Admin_Inprogress',
  ADDENDUM2_DRAFT = 'ADDENDUM2_DRAFT',
  ADDENDUM2_DOWNLOADED = 'ADDENDUM2_DOWNLOADED',
  ADDENDUM2_SUBMITTED = 'ADDENDUM2_SUBMITTED',
  ADDENDUM2_REJECTED = 'ADDENDUM2_REJECTED',
  ADDENDUM2_APPROVED = 'ADDENDUM2_APPROVED',
  PAYOUT_PAYMENT_IN_PROGRESS = 'PAYOUT_PAYMENT_IN_PROGRESS',
  PAYOUT_PAYMENT_COMPLETED = 'PAYOUT_PAYMENT_COMPLETED',
  PAYOUT_NO_PAYMENT = 'PAYOUT_NO_PAYMENT',
  AGROBONUS_HEDGING = 'AGROBONUS_HEDGING',
  AGROBONUS_BANK_DETAILS = 'AGROBONUS_BANK_DETAILS',
  AGROBONUS_TIN_DETAILS = 'AGROBONUS_TIN_DETAILS',
  AGROBONUS_AUTHORIZATION_RIGHTS = 'AGROBONUS_AUTHORIZATION_RIGHTS',
  AGROBONUS_CONTRACT_AGREEMENT = 'AGROBONUS_CONTRACT_AGREEMENT',
  GROWTH_BANK_DETAILS = 'GROWTH_BANK_DETAILS',
  GROWTH_TIN_DETAILS = 'GROWTH_TIN_DETAILS',
  GROWTH_AUTHORIZATION_RIGHTS = 'GROWTH_AUTHORIZATION_RIGHTS',
  Statistical_Form_Draft = 'Statistical_Form_Draft',
  Customer_NonValidated = 'Customer_NonValidated',
  Customer_Validated = 'Customer_Validated',
  Actual_Purchases_Draft = 'Actual_Purchases_Draft',
  Actual_Purchases_Approved = 'Actual_Purchases_Approved',
  PRICE_FIXATION_ADMIN_PROCESSED = 'Price_Fixation_Admin_Processed',
  PAYOUT = 'PAYOUT',
  //NO_PAYOUT = 'NoPayout',
  PAYOUT_IN_PROGRESS = 'PAYOUT_IN_PROGRESS',
  PAYOUT_COMPLETED = 'PAYOUT_COMPLETED',
  NO_PAYOUT = 'NO_PAYOUT',
  Validated = 'VALIDATED',
  Non_Validated = 'NOT VALIDATED',
  Approved = 'APPROVED',
  Non_Approved = 'NON APPROVED',
  NOPAYOUT = 'NO PAYOUT',
  COMPLETED = 'COMPLETED',
  PAYOUTDONE = 'PAYOUT',
  REJECTED = 'REJECTED',
}
export enum RequestSatges {
  HEADING_PAGE = 'Hedging_Page',
  BANK_DETAIL_PAGE = 'Bank_Details_Page',
  TIN_DETAILS_PAGE = 'Tin_Details_Page',
  AUTHORIZATION_RIGHTS_PAGE = 'Authorization_Rights_Page',
  CONTRACT_AGREEMENT_PAGE = 'Contract_Agreement_page',
  BONUS_AGREEMENT_PAGE = 'Bonus_Agreement_page',
  ENROLLEMENT_PAGE = 'Enrollment_page',
  GROWTH_UPLOAD_GRN = 'Growth_Upload_Grn',
  SIGN_BONUS_AGREEMENT_PAGE = 'Sign_Bonus_Agreement_page',
  PRICE_FIX_PAGE = 'Price_fix_page',
  SIGN_ADDENDUM_PAGE = 'Sign_Addendum_page',
  CAMPAIGN_SELECTION = 'CAMPAIGN_SELECTION',
  STAT_FORM_PAGE = 'Stat_Form_Page',
}

export enum RequestType {
  REGISTRATION = 'Registration',
  PRICE_FIXING = 'PriceFixing',
  PAYOUT = 'Payout',
  PLANSELECTION = 'PlanSelection',
  STAGEREGISTRATION = 'stage-Registration',
  STAGEACTUALPURCHASES = 'stage-ActualPurchases',
  STAGEREGISTRATIONPRICE_FIXING = 'stage-PriceFixing',
  STAGEPAYOUT = 'stage-Payout',
  ACTUALPURCHASES = 'ActualPurchases',
  PRICE_FIX = 'PRICE FIX', //It's same as PriceFixing, it's used in customer landing screen
}

export enum ExchangeType {
  WHEAT_EXCHANGE = 'MATIF',
  CORN_EXCHANGE = 'CBOT',
}

export enum PlanType {
  growth = 'Growth',
  agrobonus = 'Agrobonus',
  productgrowth = 'Product Growth',
}

export enum GrowthTargetType {
  volumeBased = 'Volume based growth target',
  valueBased = 'Value based growth target',
}

export enum PriceFixationType {
  CLASSIC = 'Classic',
  INTERVALIC = 'Intervalic',
}

export enum AttachmentType {
  GRN = 'GRN',
  BONUS = 'BONUS',
  STATISTICAL = 'STATISTICAL',
  TIN = 'TIN',
  ADDENDUM1 = 'ADDENDUM1',
  ADDENDUM2 = 'ADDENDUM2',
  CONFIRMATION_OTHERS = 'CONFIRMATION_OTHERS',
  TERMSHEET = 'TERMSHEET',
  CONFIRMATION_FULL_AGREEMENT = 'CONFIRMATION_FULL_AGREEMENT',
  PrivacyPolicy = 'Program Terms',
  TermsConditions = 'Terms And Conditions',
  PrivacyStatement = 'Privacy Policy',
  Commitment = 'Commitment',
  Actuals = 'Actuals',
}

export const getFormattedStatus = (status: string, t) =>
  ({
    [RequestStatus.Registration_Submitted]: t('SUBMITTED'),
    [RequestStatus.Registration_Rejected]: t('REJECTED'),
    [RequestStatus.Registration_Approved]: t('APPROVED'),
    [RequestStatus.Registration_Draft]: t('DRAFT'),
    [RequestStatus.Agrobonus_Draft]: t('DRAFT'),
    [RequestStatus.Agrobonus_Contract_Admin_Submitted]: t('SUBMITTED'),
    [RequestStatus.Agrobonus_Contract_Admin_Approved]: t('APPROVED'),
    [RequestStatus.Agrobonus_Contract_Admin_Rejected]: t('REJECTED'),
    [RequestStatus.Growth_Draft]: t('DRAFT'),
    [RequestStatus.Growth_GRN_Draft]: t('DRAFT'),
    [RequestStatus.Growth_GRN_Admin_Submitted]: t('DRAFT'),
    [RequestStatus.Growth_GRN_Admin_Approved]: t('APPROVED'),
    [RequestStatus.Growth_Bonus_Agreement_Draft]: t('DRAFT'),
    [RequestStatus.Growth_Bonus_Agreement_Submitted]: t('SUBMITTED'),
    [RequestStatus.Growth_Bonus_Agreement_Approved]: t('APPROVED'),
    [RequestStatus.Growth_GRN_Admin_Rejected]: t('DRAFT'),
    [RequestStatus.Growth_Bonus_Agreement_Reject]: t('REJECTED'),
    [RequestStatus.PRICE_Fixation_Draft]: t('PRICE FIX DRAFT'),
    [RequestStatus.Price_Fixation_Admin_Submitted]: t('SUBMITTED'),
    [RequestStatus.Price_Fixation_Admin_Addendum_Draft]: t('APPROVED'),
    [RequestStatus.Price_Fixation_Grower_Addendum_Submitted]: t('ADDENDUM 1 SUBMITTED'),
    [RequestStatus.Price_Fixation_Grower_Addendum_Approved]: t('ADDENDUM 1 APPROVED'),
    [RequestStatus.Price_Fixation_Grower_Addendum_Rejected]: t('ADDENDUM 1 REJECTED'),
    [RequestStatus.Addendum1_Downloaded]: t('ADDENDUM 1 DRAFT'),
    [RequestStatus.Agrobonus_Bonus_Agreement_Downloaded]: t('DRAFT'),
    [RequestStatus.Growth_Bonus_Agreement_Downloaded]: t('DRAFT'),
    [RequestStatus.Price_Fixation_Admin_Inprogress]: t('SUBMITTED'),
    [RequestStatus.Price_Fixation_Admin_System_Processed]: t('SUBMITTED'),
    [RequestStatus.ADDENDUM2_DRAFT]: t('ADDENDUM DRAFT'),
    [RequestStatus.ADDENDUM2_DOWNLOADED]: t('ADDENDUM DOWNLOADED'),
    [RequestStatus.ADDENDUM2_SUBMITTED]: t('ADDENDUM SUBMITTED'),
    [RequestStatus.ADDENDUM2_REJECTED]: t('ADDENDUM REJECTED'),
    [RequestStatus.ADDENDUM2_APPROVED]: t('ADDENDUM APPROVED'),
    [RequestStatus.PAYOUT_PAYMENT_IN_PROGRESS]: t('PAYOUT PAYMENT IN PROGRESS'),
    [RequestStatus.PAYOUT_PAYMENT_COMPLETED]: t('PAYOUT PAYMENT COMPLETED'),
    [RequestStatus.PAYOUT_NO_PAYMENT]: t('PAYOUT NO PAYMENT'),
    [RequestStatus.AGROBONUS_HEDGING]: t('DRAFT'),
    [RequestStatus.AGROBONUS_BANK_DETAILS]: t('DRAFT'),
    [RequestStatus.AGROBONUS_TIN_DETAILS]: t('DRAFT'),
    [RequestStatus.AGROBONUS_AUTHORIZATION_RIGHTS]: t('DRAFT'),
    [RequestStatus.AGROBONUS_CONTRACT_AGREEMENT]: t('DRAFT'),
    [RequestStatus.GROWTH_BANK_DETAILS]: t('DRAFT'),
    [RequestStatus.GROWTH_TIN_DETAILS]: t('DRAFT'),
    [RequestStatus.GROWTH_AUTHORIZATION_RIGHTS]: t('DRAFT'),
    [RequestStatus.Statistical_Form_Draft]: t('DRAFT'),
    [RequestStatus.PAYOUT_COMPLETED]: t('Payout'),
    [RequestStatus.NO_PAYOUT]: t('No Payout'),
    [RequestStatus.Validated]: t('VALIDATED'),
    [RequestStatus.Non_Validated]: t('NOT VALIDATED'),
    [RequestStatus.Approved]: t('Approved'),
    [RequestStatus.Non_Approved]: t('Non Approved'),
    [RequestStatus.NOPAYOUT]: t('NO PAYOUT'),
    [RequestStatus.COMPLETED]: t('COMPLETED'),
    [(RequestStatus.PAYOUTDONE, RequestStatus.PAYOUT)]: t('PAYOUT'),
    [RequestStatus.REJECTED]: t('REJECTED'),
  }[status] || status);

export const getFormattedRequestType = (requestType: string, t) =>
  ({
    [RequestType.STAGEREGISTRATION]: t('Registration'),
    [RequestType.REGISTRATION]: t('Campaign Selection'),
    [RequestType.PRICE_FIXING]: t('Price Fixing'),
    [RequestType.PAYOUT]: t('Payout'),
    [RequestType.PLANSELECTION]: t('Bonus Agreement'),
    [RequestType.STAGEACTUALPURCHASES]: t('Actual Purchases'),
    [RequestType.STAGEREGISTRATIONPRICE_FIXING]: t('Price Fix'),
    [RequestType.STAGEPAYOUT]: t('Payout'),
  }[requestType] || requestType);

export const getFormattedPlanType = (planType: string, t) =>
  ({
    [PlanType.growth]: t('Growth'),
    [PlanType.agrobonus]: t('Agrobonus'),
  }[planType] || planType);

export const isBgApprovedOnGraph = (status: string) => {
  const arr: Array<string> = [
    RequestStatus.Growth_Bonus_Agreement_Approved,
    RequestStatus.Price_Fixation_Admin_System_Processed,
    RequestStatus.Price_Fixation_Grower_Addendum_Approved,
    RequestStatus.Price_Fixation_Admin_Submitted,
    RequestStatus.Price_Fixation_Admin_Inprogress,
    RequestStatus.Price_Fixation_Admin_Addendum_Draft,
  ];
  return arr.includes(status);
};
export enum BankType {
  Bank = 'bank',
  Syngenta = 'syngenta',
}
export enum LockInStages {
  PRICE_FIXED = 'Price Fixed',
  OPTION_PREMIUM_ADDED = 'Option Premium',
}
export enum FeatureType {
  LE_LIST = 'LE_LIST',
  NEWS = 'NEWS',
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD',
  PRICE_FIX_V2 = 'PRICE_FIX_V2',
  PAYOUT_ESTIMATE_V2 = 'PAYOUT_ESTIMATE_V2',
  FINAL_PAYOUT_V2 = 'FINAL_PAYOUT_V2',
  FINAL_PAYOUT_V2_BUTTON = 'FINAL_PAYOUT_V2_BUTTON',
  FINAL_PAYOUT_GENERATE_ADDENDUM_BUTTON = 'FINAL_PAYOUT_GENERATE_ADDENDUM_BUTTON',
}
export enum GridType {
  LE_LIST_GRID = 'LE List Grid',
  PRICE_FIX_V2_GRID = 'Price Fix Grid',
  PAYOUT_ESTIMATE_V2_GRID = 'Payout Grid',
  FINAL_PAYOUT_V2_GRID = 'Final Payout Grid',
  PAYOUT_FINAL = 'Final Payout Grid RU',
}
export enum ActionModeType {
  VIEW = 'view',
  EDIT = 'edit',
  CUSTOMIZE_EDIT = 'customizeEdit',
  New = 'new',
}

export enum CustomerType {
  Distributor = 'Distributor',
  Grower = 'Grower',
  Large_Growers = 'Large Growers',
}

export enum ArgentinaRegion {
  NOA = 'NOA',
  NEA = 'NEA',
  PHS = 'PHS',
  NORTE = 'NORTE',
  ESTE = 'ESTE',
  SUR = 'SUR',
  KAM = 'KAM',
}

export const removeOtherDocsFromContract = (data) => {
  if (data?.length > 0) {
    return data?.filter((file) => !file?.doc_type?.includes('Training_Documents'));
  }
  return [];
};
